/**
 * Seeq REST API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 66.9.0-v202502011536-CD
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import * as models from './models';

/**
 * The release notes for recently installed versions
 */
export interface IssueSummaryOutputV1 {
    /**
     * The timestamp of when this issue was installed on the server
     */
    "installedOn": string;
    /**
     * The type of issue
     */
    "issueType": IssueTypeEnum;
    /**
     * The unique key that identifies the issue
     */
    "key": number;
    /**
     * A short description of what the issue
     */
    "summary": string;
    /**
     * The version associated with this issue
     */
    "version": string;
}

export enum IssueTypeEnum {
    BUGFIX = 'BUGFIX' as any,
    FEATURE = 'FEATURE' as any
}
